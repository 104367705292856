export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73')
];

export const server_loads = [];

export const dictionary = {
		"/": [13],
		"/ai": [14,[2]],
		"/ai/[projectId]": [15,[2,3]],
		"/ai/[projectId]/prompts": [16,[2,3]],
		"/calendar": [17],
		"/clients": [18],
		"/clients/list": [31],
		"/clients/[clientId]": [19,[4]],
		"/clients/[clientId]/bookings": [20,[4]],
		"/clients/[clientId]/health-programs": [21,[4]],
		"/clients/[clientId]/healthplan": [22,[4]],
		"/clients/[clientId]/message": [23,[4,5]],
		"/clients/[clientId]/message/conversation": [25,[4,5]],
		"/clients/[clientId]/message/[conversationId]": [24,[4,5]],
		"/clients/[clientId]/notes": [26,[4]],
		"/clients/[clientId]/overview": [27,[4]],
		"/clients/[clientId]/profile": [28,[4]],
		"/clients/[clientId]/testresults": [29,[4]],
		"/clients/[clientId]/testresults/[reportId]": [30,[4]],
		"/companies": [32],
		"/companies/[companyId]/benefits": [33,[6]],
		"/companies/[companyId]/clients": [34,[6]],
		"/companies/[companyId]/health-programs": [35,[6]],
		"/companies/[companyId]/message": [36,[6,7]],
		"/companies/[companyId]/message/[conversationId]": [37,[6,7]],
		"/companies/[companyId]/roles": [38,[6]],
		"/companies/[companyId]/settings": [39,[6]],
		"/companies/[companyId]/tags": [40,[6]],
		"/companies/[companyId]/testphases": [41,[6]],
		"/companies/[companyId]/testphases/[testPhaseId]": [42,[6]],
		"/company-messages": [43,[8]],
		"/company-messages/bulk-messaging": [44,[8]],
		"/dashboard": [45],
		"/feature-toggle": [46],
		"/feature-toggle/new": [48],
		"/feature-toggle/[featureId]": [47],
		"/feedbacks": [49],
		"/health-programs": [~50],
		"/health-programs/[healthProgramId=integer]": [51,[9]],
		"/health-programs/[listType]": [52],
		"/kpis": [53],
		"/login": [54],
		"/message-logs": [55],
		"/messages": [56,[10]],
		"/messages/all": [57,[10]],
		"/messages/bulk-messaging": [58,[10]],
		"/messages/monitored": [59,[10]],
		"/okr": [60],
		"/profile": [61],
		"/register": [62],
		"/reports/merge": [67,[11]],
		"/reports/[reportId=integer]": [63,[12]],
		"/reports/[reportId=integer]/reportinformation": [64,[12]],
		"/reports/[reportId=integer]/survey": [65,[12]],
		"/reports/[reportType]": [66,[11]],
		"/reset-password": [68],
		"/settings": [69],
		"/stats": [70],
		"/styling-test": [71],
		"/unauthorized": [72],
		"/users": [73]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';